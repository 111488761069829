<template>
    <div>
        <home-infos @click="show_reservation=!show_reservation"></home-infos>
        <product-searcher v-if="show_reservation"></product-searcher>
    </div>
</template>

<script>
import ProductSearcher from '@/components/product-searcher.vue'
import HomeInfos from '@/components/home-infos.vue'

/*
@desc Main Home page (booking search, wrapper for `product-searcher` and `home-infos` component)
*/
export default {
    name: 'Home',
    components: { ProductSearcher, HomeInfos },
    data: () => ({
        show_reservation: false
    })
}
</script>
