<template>
    <v-container :class='["home_info_container text-center",open_search ? "search":""]'>
        <h1>{{$t('home_infos.subtitle')}}</h1>
        <h1
            style="color:#FF686B; ">Information importante : changement de site web !<br> Pour faire une réservation, vous devez maintenant aller sur <a href="https://ntn.g2roues.com/">https://ntn.g2roues.com/</a>
        </h1>
        <v-btn
            text
            color="primary"
            class='back_btn'
            @click="open_search=false;$emit('click')"
        >
            <v-icon class='mr-3 ml-0'>mdi-arrow-left</v-icon>{{$t('home_infos.moreinfo')}}
        </v-btn>
        <div
            :class="['app_infos',open_search ? 'hide' : '']"
            ref="app_infos"
            id="app_infos"
        >
            <v-btn
                color="secondary"
                class=' pa-7 mt-5 mb-7 geo_btn'
                large
                @click="open_search=true;$emit('click')"
            >
                <v-icon class='mr-3 geo_btn_icon'>mdi-crosshairs-gps</v-icon> {{$t('home_infos.findaproduct')}}
            </v-btn>
            <v-card-text class='mb-5'>{{$t('home_infos.content')}}
            </v-card-text>
            <v-container class='img_displayer text-center'>
                <!--<v-img
                    src="/images/illus/FALCOPREMIUM3.jpg"
                    class='overlay top left'
                    contain
                />-->
                <v-img
                    src="/images/illus/KIOSKAVELO.jpg"
                    height="400px"
                    contain
                />
              <!--<v-img
                  src="/images/illus/rackdoubleetage.jpg"
                  class='overlay bottom right'
                  contain
              />-->
          </v-container>
      </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
/*
@desc First component displayed in Home for app infos
*/
export default {
  data: () => ({
      open_search: false,
  }),
  watch: {
      /*
      @desc Change back Illus state when info display state changes
      */
      'open_search': {
          handler() {
              if (!this.open_search) this.set_back_state('show_infos')
          },
          immediate: true
      }
  },
  methods: {
      ...mapActions(['set_back_state'])
  },
  mounted() {
      const height = Math.max(this.$refs.app_infos.offsetHeight, window.innerHeight - 300)
      this.$refs.app_infos.style.height = height + 'px'
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap%22");

.app_infos {
  overflow: hidden;
  width: 100%;
  transition: all 0.5s;
}
.back_btn {
  transition: all 0.5s;
  height: 0px !important;
  overflow: hidden;
}
.search .back_btn {
  height: 36px !important;
}
.app_infos.hide {
  height: 0px !important;
}

.home_info_container {
  width: 800px;
  max-width: 100%;
}
.home_info_container h1 {
  font-family: "Merriweather Sans","Playfair Display", arial, helvetica;
  overflow: hidden;
  font-size: 40px;
  transition: all 0.5s;
}
.mobile .home_info_container h1 {
  margin-top: 50px;
  font-size: 30px;
  height: 200px;
}
.home_info_container.search h1 {
  margin-top: -20px;
}
.mobile .home_info_container.search h1 {
  margin-top: 0px;
  height: 0px;
}
.img_displayer {
  position: relative;
  width: 100%;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.img_displayer .overlay {
  position: absolute;
  height: 50%;
  width: 50%;
  z-index: 1;
}

.img_displayer .overlay.top {
  bottom: 50%;
}
.img_displayer .overlay.bottom {
  top: 50%;
}
.img_displayer .overlay.left {
  right: 50%;
}
.img_displayer .overlay.right {
  left: 50%;
}
.mobile .img_displayer .overlay.right,
.mobile .img_displayer .overlay.left {
  left: 50% !important;
  transform: translateX(-50%);
  right: none;
}
.mobile .img_displayer .overlay.top {
  bottom: 60%;
}
.mobile .img_displayer .overlay.bottom {
  top: 60%;
}
.geo_btn_icon {
  animation: gelatine 1s infinite;
}

@keyframes gelatine {
  from,
  50%,
  to {
      transform: scale(1, 1);
  }
  12% {
      transform: scale(0.8, 1.2);
  }
  25% {
      transform: scale(1.2, 0.8);
  }
  37% {
      transform: scale(0.95, 1.05);
  }
}
</style>