<template>
    <span>
        <!-- ---------------------------------------- MENU BUTTON -->
        <v-btn
            v-if="is_button"
            icon
            color='primary'
            @click="menu_data.open=!menu_data.open"
        >
            <v-icon>mdi-menu</v-icon>
        </v-btn>
        <!-- ---------------------------------------- TOP MENU -->
        <template v-if="is_top_menu">
            <v-btn
                v-for="link_data in filtered_links"
                :key="link_data.label"
                :to="link_data.link"
                text
                :color="route == link_data.link ? 'secondary':'primary'"
                style="margin-top:4px"
            >{{link_data.label}}</v-btn>
        </template>
        <!-- ---------------------------------------- DRAWER -->
        <v-navigation-drawer
            v-if="is_left_menu"
            v-model="menu_data.open"
            temporary
            fixed
        >
            <v-card-title>{{$t('left_menu.menu')}}</v-card-title>
            <v-divider></v-divider>
            <v-card
                v-for="link_data in filtered_links"
                :key="link_data.label"
                class='pa-5 ma-3'
                elevation="0"
                :to="link_data.link"
                :color="route == link_data.link ? 'secondary':''"
                :disabled="route == link_data.link"
                :dark="route == link_data.link"
            >
                <v-icon class='mr-3'>mdi-{{link_data.icon}}</v-icon> {{link_data.label}}
            </v-card>
            <v-menu v-model="languageMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="pa-5" style="position:absolute;bottom:0px; left: 50%; transform: translate(-50%, -50%);" v-bind="attrs" v-on="on" text plain>
                  <v-icon left>mdi-translate</v-icon>
                  {{ activeLang }}
                  <v-icon small right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list dense v-model="$i18n.locale">
                <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
                  <v-list-item-title>{{ lang.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-navigation-drawer>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
/*
@desc Multi Component Left Menu (drawer / ham button / top menu)
*/
export default {
    props: ['menu_data', 'type'],
    data: () => ({
        group: null,
        route: null,
        langs: [
          { title: 'English', value: 'en' },
          { title: 'Français', value: 'fr' }
        ],
        dialog: false,
        languageMenu: false
    }),
    watch: {
        'menu_data.open': {
            handler() {
                this.route = this.$router.history.current.fullPath
            },
            immediate: true
        },
        /*
        @desc Update button display depending on current route
        */
        $route() {
            this.route = this.$router.history.current.fullPath
        },
    },
    computed: {
        ...mapGetters(['is_connected']),
        /*
        @desc Links are filtered depending on user connection status (ROLES) and device format
        */
        filtered_links() {
            return this.links.filter(lnk => (!lnk.is_connected || this.is_connected) && (!lnk.top_hidden || this.$is_mobile))
        },
        /*
        @desc Check if sub component asked is drawer
        */
        is_left_menu() {
            return this.type == 'left_menu'
        },
        /*
        @desc Check if sub component asked is ham button
        */
        is_button() {
            return this.type == 'button'
        },
        /*
        @desc Check if sub component asked is top menu
        */
        is_top_menu() {
            return this.type == 'top_menu'
        },
        links() {
          return [
          { label: this.$t('left_menu.myorders'), icon: 'clipboard-text', link: '/orders', is_connected: true },
          { label: this.$t('left_menu.contacts'), icon: 'at', link: '/contacts', top_hidden: true },
          { label: this.$t('left_menu.terms'), icon: 'scale-balance', link: '/cgu', top_hidden: true },
        ]
        },
        activeLang() {
          return this.$i18n.locale
        }
    },

    methods: {
      handleMenuItemClick (lang) {
        this.activeLang = lang.title;

        if (this.$i18n.locale !== lang.value) {
          this.$i18n.locale = lang.value;
        }
      }
    },
      /*
      @desc Init current detected route
      */
    mounted() {
      this.route = this.$router.history.current.fullPath
    }
}
</script>

<style scoped>
</style>